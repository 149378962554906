.modal_message {
  align-items: center;
  background-color: rgba(34, 36, 49, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.modal_container {
  color: #000;
  min-width: 400px;
}

.modal_header {
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 56px;
  position: relative;
  width: 100%;
}

.modal_logo {
  display: flex;
  left: 6px;
  position: absolute;
  top: 3px;
}

.modal_title {
  color: #464646;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.36572px;
  line-height: 36px;
  margin-top: 0;
  padding: 10px 0;
  text-align: center;
}

.modal_close {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 15px;
  top: 20px;
  transition: opacity 0.2s ease-in-out;
  will-change: opacity;
}

.modal_body {
  background-color: #fff;
  background-image: url('/images/get_started_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 0 0 8px 8px;
  min-height: 200px;
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.message_content {
  align-items: center;
  color: #000;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin: 20px auto;
  min-height: 85px;
  text-align: center;
  width: 320px;
}

.modal_actions {
  align-items: center;
  display: flex;
  justify-content: center;
}

.join_outing_input {
  background: #fff;
  border: 1.69142px solid #207ac4;
  border-radius: 3.82404px;
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  height: 35px;
  letter-spacing: 0.877417px;
  line-height: 24px;
  margin-top: 15px;
  padding: 0 15px;
  text-align: center;
  width: 100%;
}

.feedback_error {
  color: #f00;
  font-size: 12px;
  height: 14px;
  line-height: 14px;
  margin: 4px 0;
}

.modal_footer {
  align-items: center;
  background: #fff;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #eee;
  display: flex;
  height: 50px;
  justify-content: center;
  text-align: center;
}

.modal_footer.confirm {
  justify-content: space-around;
}

.modal_footer_btn {
  align-items: center;
  background: linear-gradient(0deg, #0354ab, #0354ab), #f7f7f7;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  justify-content: center;
  line-height: 17px;
  min-width: 100px;
  padding: 0 15px;
  text-align: center;
}

.modal_footer_btn:not(:first-child) {
  margin-left: 10px;
}

@media (max-width: 400px) {
  .modal_container {
    min-width: unset;
    width: calc(100% - 12px);
  }
}
