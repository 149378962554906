.page_container {
  background: #207ac4;
  min-height: 100vh;

  .page_body {
    margin: 0 auto;
    max-width: 1184px;
    text-align: center;

    .page_title {
      color: #fff;
      margin: 168px 0 10px;
    }

    .page_subtitle {
      color: #fff;
    }

    .page_description {
      color: #fff;
      margin: 37px auto;
      max-width: 708px;
    }

    .page_btns {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 80px;

      .page_btn {
        align-items: center;
        background: #ffe34f;
        border-radius: 73px;
        cursor: pointer;
        display: flex;
        font-size: 15px;
        font-weight: 800;
        height: 44px;
        justify-content: center;
        line-height: 24px;
        margin: 0 10px;
        width: 162px;

        &:hover {
          background: #ffcd4f;
        }

        a {
          color: #000;
          text-decoration: none;
        }
      }
    }

    .page_img {
      height: 235px;
      margin: 0 auto 80px;
      position: relative;
      width: 349px;
    }
  }
}

@media (max-width: 500px) {
  .page_container {
    .page_body {
      padding: 0 32px;

      .page_title {
        margin: 124px 0 10px;
      }

      .page_subtitle {
        color: #fff;
      }

      .page_description {
        color: #fff;
        margin: 13px auto 39px;
      }

      .page_btns {
        flex-flow: wrap;
        margin-bottom: 40px;

        .page_btn {
          &:first-child {
            margin: 0 10px 24px 0;
          }

          &:nth-child(2) {
            margin: 0 0 24px 10px;
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      .page_img {
        margin: 0 auto 30px;
      }
    }
  }
}

@media (max-width: 390px) {
  .page_container {
    .page_body {
      .page_btns {
        margin-bottom: 26px;

        .page_btn {
          margin: 0 20px 24px !important;
        }
      }

      .page_img {
        height: 220px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 375px) {
  .page_container {
    .page_body {
      .page_img {
        height: 209px;
      }
    }
  }
}
@media (max-width: 360px) {
  .page_container {
    .page_body {
      .page_img {
        height: 199px;
      }
    }
  }
}

